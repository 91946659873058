body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loaderImage{
  z-index: 9999;
  display:block;
}
.loader-container {
  z-index: 9999;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  text-align:center;
}

.card.card-tale {
  background: #f07c00 !important;
  color: #ffffff;
}

h3 ,h4 ,.icon-menu{
  color:#f07c00 !important;
}
.card{
  border: #f07c00 2px solid !important;
}
textarea {
  margin:0px 0px;
  padding:5px;
  min-height:16px;
  line-height:16px;
  width:96%;
  display:block;
  margin:0px auto;    
}


/* table scroll */
#collapse1{
  overflow-y:scroll;
  max-height:500px;
}
.tableFixHead th { position: sticky; top: 0;
background: white; }
/* table scroll */

.ck.ck-toolbar.ck-toolbar_grouping>.ck-toolbar__items {
  flex-wrap: wrap !important;
}